import { ACPVariant } from 'apps/acp/variants/acp-variant';

const colors = {
  neutral: {
    color50: '#f8fafb',
    color100: '#f1f4f6',
    color200: '#eaeff2',
    color300: '#c4c7c9',
    color400: '#9d9fa0',
    color500: '#807f83',
    color600: '#5f5f5f',
    color700: '#54534a',
    color800: '#303030',
    color900: '#181818'
  },
  default: {
    color50: '#d5dfe7',
    color100: '#aabfcf',
    color200: '#80a0b7',
    color300: '#55809e',
    color400: '#2b6086',
    color500: '#00406e',
    color600: '#003358',
    color700: '#002642',
    color800: '#001a2c',
    color900: '#000d16'
  },
  accent: {
    color50: '#d9f7f6',
    color100: '#b3efed',
    color200: '#8ee7e4',
    color300: '#68dfdb',
    color400: '#42d7d2',
    color500: '#1ccfc9',
    color600: '#16a6a1',
    color700: '#117c79',
    color800: '#0b5350',
    color900: '#062928'
  },
  positive: {
    color50: '#ebf4d5',
    color100: '#d6e9aa',
    color200: '#c2de80',
    color300: '#add355',
    color400: '#99c82b',
    color500: '#84bd00',
    color600: '#6a9700',
    color700: '#4f7100',
    color800: '#354c00',
    color900: '#1a2600'
  },
  negative: {
    color50: '#f8d5d9',
    color100: '#f1aab3',
    color200: '#eb808e',
    color300: '#e45568',
    color400: '#dd2b42',
    color500: '#d6001c',
    color600: '#ab0016',
    color700: '#800011',
    color800: '#56000b',
    color900: '#2b0006'
  },
  special: {
    color50: '#f7e0da',
    color100: '#e7c1b5',
    color200: '#e7a290',
    color300: '#df836a',
    color400: '#d76445',
    color500: '#cf4520',
    color600: '#a6371a',
    color700: '#7c2913',
    color800: '#531c0d',
    color900: '#290e06'
  }
};

const variant: ACPVariant = {
  id: 'www.mynetspend.com',
  productTitle: 'Netspend Disbursement Account',
  productType: 'disbursement',
  accessDomain: {
    subdomain: 'www',
    domain: 'mynetspend',
    topLevelDomain: 'com'
  },
  colors,
  legacyAcpTheme: 'netspend-disbursement',
  legacyAcpBrandName: 'netspend-disbursement',
  legacyLegosTheme: 'netspend-disbursement',
  status_bar_background_color: colors.default.color500,
  status_bar_foreground_color: 'dark',
  contactInfo: {
    name: 'Netspend',
    contact_address_line1: 'PO Box 2136',
    contact_address_line2: 'Austin, TX 78768-2136',
    contact_address_line3: '',
    customer_support_email: 'customerservice@mynetspend.com',
    fax_formatted: '(888) 628-9125',
    phone_formatted: '(877) 562-2235',
    phone: '8775622235',
    phone_international: '737-220-8956',
    balance_check_phone: '8663877363',
    balance_check_phone_formatted: '1-866-387-7363',
    pre_funded_checks_phone: '18778147683',
    pre_funded_checks_phone_formatted: '1-877-814-7683'
  },
  configuration: {
    apiKeys: {
      appsFlyerDevKey: '5Us7NeWk6WVjGLsAnKioSk',
      'google-tag-manager-container-id': 'GTM-NQXKPD',
      'google-tag-manager-container-id-mobile': 'GTM-MWBTT5M',
      paypalFraudNetPayerIdProd: '',
      paypalFraudNetPayerIdTest: ''
    },
    featureToggles: {
      activationSetExternalId: false,
      appsFlyerEnabled: false,
      combinedTransactionsMasterAndSub: false,
      'embedded-activation': true,
      embeddedPayNearMe: false,
      hasNotificationCenter: false,
      mobilePBREnabled: true,
      preOnboardingEnabled: false,
      showAccountDisplayNickname: false,
      showAcquisitionLink: false,
      showATMFriendlyCards: false,
      showDisplayNameForSubAccount: false,
      showEmailInformationButton: true,
      showFeePlanPage: false,
      showHowToEarnRewardPointLink: true,
      showRegisterLink: true,
      showScanCardRegistration: true,
      showMarketingSiteLink: true,
      showFreeAtmFinderLink: false,
      showWUTransfers: false,
      showReloadLocationLink: false
    },
    misc: {
      layoutComponentOverride: false,
      layoutAppBarInverted: false,
      loader: 'generic',
      payNearMeButtonTextColor: '#FFFFFF',
      payNearMeActionBarBackgroundColor: '#0092BC',
      payNearMeTitleTextColor: '#FFFFFF',
      payNearMeColorAccent: '#84bd00',
      marketingSiteUrl: 'https://www.mynetspend.com',
      marketingSiteLinkText: 'mynetspend.com',
      locationSearchMax: '25'
    },
    content: {
      copyrightStatement:
        'Ouro Global, Inc. All rights reserved worldwide. All other trademarks and service marks belong to their owners.'
    },
    disclaimers: {},
    sections: [
      'dashboard-gpr',
      'dashboard-lite',
      'dashboard-handoff',
      'layout',
      'manage-gpr',
      'move-money',
      'overdraft',
      'locations',
      'direct-deposit',
      'direct-deposit-mobile-panel',
      'statements',
      'contact-us',
      'restrictions-readonly',
      'feeplan',
      'western-union',
      'disclosure-dictionary',
      'check-deposit',
      'checks',
      'quickbooks-oauth',
      'points',
      'post-handoff-to-activate-register',
      'upgrade',
      'offers',
      'offers-intro',
      'tours',
      'post-install-multi-screen-acquisition-flow',
      'echecks',
      'external-loyalty',
      'secure-inbox',
      'benefit-center',
      'atm-finder',
      'savings',
      'pay-bills',
      'premier-plan',
      'activation',
      'savings-transfer',
      'anytime-alerts',
      'anytime-alerts-upgrade',
      'change-username',
      'change-password',
      'mobile-check-load',
      'feedback',
      'rent-track',
      'direct-deposit-form',
      'dynamic-faq',
      'billpay',
      'card',
      'refer-a-friend',
      'close-account',
      'points-learn-more',
      'family-cards',
      'bank-transfers',
      'handoff',
      'debit-card-transfers',
      'card-use-settings',
      'secure-upload',
      'digital-wallet',
      'me-to-me-transfer',
      'money-gram',
      'survey',
      'dda-activation',
      'debit-card-transfers-disbursement',
      'spend-money',
      'fraud-detection',
      'spending-tracker',
      'connected-banks',
      'interstitials',
      'dd-tracker'
    ]
  }
};

export default variant;
